<template>
  <div class="blog-detail-wrapper">

    <!-- blogs -->
    <b-row class="blog-list-wrapper">
      <b-card no-body class="col-md-12">
        <b-card-header class="pb-50">
          <h5>
            Фильтры
          </h5>
        </b-card-header>
        <b-card-body>
          <b-row>
            <b-col
                cols="12"
                md="12"
                class="mb-md-0 mb-2"
            >
              <label
                  for="search-query"
                  class="text-nowrap mr-50"
              >Запрос:</label>
              <b-form-input
                  id="search-query"
                  v-model="searchQuery"
                  placeholder="Зеленая миля"
                  @keyup.enter="fetchMoviesList()"
              />
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>

      <b-overlay
          :show="isLoading"
          rounded="sm"
      >
        <b-table
            ref="refMoviesListTable"
            :items="moviesList"
            responsive
            :fields="tableColumns"
            :sort-by.sync="sortBy"
            :sort-desc.sync="isSortDirDesc"
            primary-key="id"
            show-empty
            empty-text="No matching records found"
            class="position-relative"
            no-local-sorting
        >
          <!-- Column: Id -->
          <template #cell(id)="data">
            #{{ data.item.id }}
          </template>

          <!-- Column: Movie -->
          <template #cell(movie)="data">
            <b-media vertical-align="center">
              <template #aside>
                <b-link
                    :to="{name: 'movie-view', params: { id: data.item.id }}"
                >
                  <b-img
                      width="120"
                      thumbnail
                      rounded
                      fluid
                      class="poster"
                      :class="(isFilm(data.item.type)) ? 'poster-movie' : 'poster-series'"
                      :src="data.item.poster_preview"
                  />
                </b-link>
              </template>
              <span class="font-weight-bold d-block">
              <b-link
                  :to="{name: 'movie-view', params: { id: data.item.id }}"
                  class="h2"
                  :class="(isFilm(data.item.type)) ? 'title-movie' : 'title-series'"
              >{{ data.item.title_ru }} ({{ data.item.year }})</b-link>
              <b-badge
                  :href="`https://kinopoisk.ru/film/${data.item.kp_id}`"
                  target="_blank"
                  variant="success"
                  class="ml-1"
              >
                <feather-icon
                    icon="LinkIcon"
                    class="mr-25"
                />
                <span>КП</span>
              </b-badge>
              <b-badge
                  :href="`https://www.imdb.com/title/${data.item.imdb_id}/`"
                  target="_blank"
                  variant="warning"
                  class="ml-1"
              >
                <feather-icon
                    icon="LinkIcon"
                    class="mr-25"
                />
                <span>IMDB</span>
                  </b-badge>
                </span>
              <small class="text-muted">{{ data.item.title_en }}</small>
              <span class="d-block text-nowrap">

              </span>
            </b-media>
          </template>

          <!-- Column: Type -->
          <template #cell(type)="data">
            <feather-icon :icon="GetTypeIcon(data.item.type)" v-b-tooltip.hover.top="data.item.type"/>
          </template>

          <!-- Column: Rating -->
          <template #cell(rating)="data">
            <span class="font-weight-bold font-large-1 ml-1">
              <span
                  :class="(data.item.rating_kp < 5) ? 'text-danger' : (data.item.rating_kp < 7) ? 'text-gray' : 'text-success'"
              >
                {{ ratingFmt(data.item.rating_kp) }}
              </span>
            </span>
          </template>

          <!-- Column: Creation Date -->
          <template #cell(created_at)="data">
          <span class="text-nowrap mr-1">
             {{ formatDate(data.item.created_at) }}
          </span>
          </template>

          <!-- Column: Actions -->
          <template #cell(actions)="data">

            <div class="text-nowrap">

            </div>
          </template>
        </b-table>
      </b-overlay>

      <b-col cols="12">
        <!-- pagination -->
        <div class="my-2">
          <b-pagination
              v-model="page"
              per-page="20"
              align="center"
              :total-rows="total"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
              />
            </template>
          </b-pagination>
        </div>
      </b-col>
    </b-row>

    <!--/ blogs -->

  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BFormInput,
  BCardText,
  BCardTitle,
  BMedia,
  BAvatar,
  BMediaAside,
  BMediaBody,
  BImg,
  BCardBody,
  BLink,
  BBadge,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
  BPagination,
  BCardHeader,
  BTable,
  BButton,
  VBTooltip,
  BOverlay,
} from 'bootstrap-vue'
import {avatarText, kFormatter, formatDate, ratingFmt} from '@core/utils/filter'
import ContentWithSidebar from '@core/layouts/components/content-with-sidebar/ContentWithSidebar.vue'
import store from "@/store";
import {onUnmounted, ref, watch} from "@vue/composition-api";
import useMoviesList from "./useMoviesList";
import movieStoreModule from "@/views/database/movieStoreModule";
import vSelect from "vue-select";

export default {
  components: {
    BOverlay,
    BRow,
    BCol,
    BCard,
    BFormInput,
    BCardText,
    BCardBody,
    BCardTitle,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BLink,
    BBadge,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BImg,
    BPagination,
    BCardHeader,
    BTable,
    BButton,
    ContentWithSidebar,
    vSelect,
  },
  data() {
    return {
    }
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  methods: {
    kFormatter,
    formatDate,
    ratingFmt,
    GetTypeIcon(type) {
      if (type === 'FILM') {
        return 'FilmIcon'
      }
      if ((type === 'TV_SERIES') || (type === 'MINI_SERIES')) {
        return 'TvIcon'
      }
      if (type === 'TV_SHOW') {
        return 'CameraIcon'
      }
      if (type === 'UNKNOWN') {
        return 'InfoIcon'
      }
      if (type === 'VIDEO') {
        return 'VideoIcon'
      }
      return 'AlertTriangleIcon'
    },
    isFilm(type) {
      if (type === 'FILM') {
        return true
      }
      if ((type === 'TV_SERIES') || (type === 'MINI_SERIES')) {
        return false
      }
      if (type === 'TV_SHOW') {
        return false
      }
      if (type === 'UNKNOWN') {
        return false
      }
      if (type === 'VIDEO') {
        return true
      }
      return false
    }
  },
  setup() {
    const STORE_MODULE_NAME = 'movies'

    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, movieStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
    })

    const page = ref(1)
    const searchQuery = ref(null)
    const total = ref(null)
    const isLoading = ref(false)

    const {
      perPage,
      currentPage,
      totalMovies,
      dataMeta,
      perPageOptions,
      sortBy,
      isSortDirDesc,
      refMovieListTable,
    } = useMoviesList()

    // Table Handlers
    const tableColumns = [
      {key: 'id', label: '#', sortable: true},
      {key: 'movie', sortable: false},
      {key: 'year', sortable: true, label: "Год"},
      {key: 'type', sortable: false, label: "Тип"},
      {key: 'rating', sortable: false, label: "Рейтинг КП"},
      {key: 'votes_kp', sortable: true, label: "Оценок"},
      {key: 'created_at', sortable: true, label: "Дата добавления"},
      //{key: 'actions'},
    ]

    const moviesList = ref([])
    const fetchMovies = (...args) => store.dispatch('movies/fetchMovies', ...args)

    const fetchMoviesList = () => {
      isLoading.value = true
      fetchMovies({
        q: searchQuery.value,
        page: page.value,
        sort: sortBy.value,
        sortDesc: isSortDirDesc.value,
      })
          .then(response => {
            moviesList.value = response.data.data
            total.value = response.data.meta.total
            isLoading.value = false
          })
    }

    fetchMoviesList()

    watch([/*searchQuery, */page, sortBy, isSortDirDesc], () => {
      fetchMoviesList()
    }, {
      deep: true,
    })

    return {
      fetchMovies,
      tableColumns,
      perPage,
      currentPage,
      totalMovies,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refMovieListTable,
      moviesList,
      page,
      total,
      avatarText,
      fetchMoviesList,
      isLoading,
    }
  },
}
</script>

<style lang="scss">
.poster-movie {
  border-color: #006d8d;
}

.poster-series {
  border-color: #b97500;
}

.poster {
  border-width: 2px;
}

.title-movie {
  color: #006d8d;
}

.title-series {
  color: #b97500;
}
</style>